body {
    background-color: #9f9da7;
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
    color: #2b3e51;
    background-image: url('../img/login_Background.jpg');
}

h2 {
    font-weight: 300;
    text-align: center;
}

p {
    position: relative;
}

a,
a:link,
a:visited,
a:active {
    color: #3ca9e2;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#login-form-wrap {
    background-color: #fff;
    width: 40%;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 15%;

}

#login-form {
    margin-top: 20px;
}

/* @media(max-width:1000px) {
    .Login_container {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        width: 900px;
        height: auto;
    }
} */
.Login_container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 10%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: auto;
}
.toggle {
    border: none;
    background: none;
    position: absolute;
    right: .75rem;
    top: 1rem;
    z-index: 9;
}

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    height: 60px;
    line-height: 60px;
    border-radius: 4px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 0 0 0 10px;
    margin: 0;
    color: #8a8b8e;
    border: 1px solid #c2c0ca;
    font-style: normal;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    display: inline-block;
    background: none;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #212529;
    border-width: 2px;
}

input[type="text"]:focus:invalid,
input[type="password"]:focus:invalid {
    color: #3ca9e2;
    border-color: #3ca9e2;
}

input[type="text"]:valid~.validation,
input[type="password"]:valid~.validation {
    display: block;
    border-color: #0C0;
}

input[type="text"]:valid~.validation span,
input[type="password"]:valid~.validation span {
    background: #0C0;
    position: absolute;
    border-radius: 6px;
}

input[type="text"]:valid~.validation span:first-child,
input[type="password"]:valid~.validation span:first-child {
    top: 30px;
    left: 14px;
    width: 20px;
    height: 3px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

input[type="text"]:valid~.validation span:last-child,
input[type="password"]:valid~.validation span:last-child {
    top: 35px;
    left: 8px;
    width: 11px;
    height: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.validation {
    display: none;
    position: absolute;
    content: " ";
    height: 60px;
    width: 30px;
    right: 15px;
    top: 0px;
}

input[type="submit"] {
    border: none;
    display: block;
    background-color: #212529;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 18px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

input[type="submit"]:hover {
    background-color: #fdfdfd;
    color: #212529;
    border: #212529 solid;
    font-weight: bold;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#create-account-wrap {
    background-color: #eeedf1;
    color: #8a8b8e;
    font-size: 20px;
    width: 100%;
    padding: 30px 0;
    margin-top: 10px;
    border-radius: 0 0 4px 4px;
}

.forgetPassword {
    text-decoration: none;
    color: #212529 !important;
}

.update-button,
.auto-fill {
    margin-top: 500px;
}

.accordion-simple>.active {
    display: block;
}

.accordion__faq .inactive {
    display: none;
}

.form_custom {
    width: 100% !important;
    border: 1px solid grey;
}

.image_custom {
    width: 450px;
    height: auto;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.grid-container1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}
.grid-container2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
}
.bg-pgreen{
    background-color: #0C0;
}