/* Error */
.error {
    font-size: medium;
    font-weight: 300;
    background-color: darksalmon;
}

/* Planogram */
.annotationButton {
    width: 115px;
    height: 40px;
}

.ClearActions {
    padding-bottom: 25px;
    text-align: center;
    margin-right: 10px;
}

.ClearActions:hover,
.clearAllButton:hover {
    cursor: pointer;
}

.clearAllButton {
    margin-left: 50px;
}

.clearButton,
.clearAllButton {
    font-size: 14px;
    width: 100px;
    height: auto;
}

.annotation {
    margin: 100px;
    height: 480px;
    width: 640px;
}

.annotaionCardBody {
    margin-top: 500px;
}

.updateButton {
    margin-left: 150px;
    margin-top: 500px;
    font-size: 20px !important;
}

.AutoFillButton {
    margin-left: 100px;
    margin-top: 500px;
    font-size: 20px !important;
}

.DetailsLabels {
    font-size: 18px;
    color: #5a5a5a;
    font-weight: 600;

}

/* Loader */
.loader {
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100%;
}

.loaderAlign {
    position: absolute;
    top: 30%;
    left: 50%;
    text-align: center;
    margin-left: -35px;
    margin-top: -35px;
    z-index: 9999;
}

.modal {
    display: block;
}

.modal-body {
    padding: 0px 0px 30px -15px;
}

.loaderBackGround {
    width: 100% !important;
}

.text-center {
    text-align: center;
}

/* SideBar */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
    --header-height: 3rem;
    --nav-width: 285px;
    --first-color: #fff !important;
    --first-color-light: #0c0c0e;
    --secondary-color: #000000;
    --trinary-color: #006e74;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*,
::before,
::after {
    box-sizing: border-box
}

body {
    position: relative;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s;
}

.sideBarContainer {
    border-radius: 200px;
}

a {
    text-decoration: none
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: .5s
}

.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer
}

.header_img {
    /* width: 35px; */
    /* height: 35px; */
    justify-content: center;
    overflow: hidden
}

.header_img img {
    width: 100px;
    display: block;
    margin: auto;
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--first-color);
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    box-shadow: 1px 2px 26px rgb(0 0 0 / 20%);
}

.nav-sidebar {
    height: 100%;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem
}

.signout:hover {
    cursor: pointer;
}

.nav_name {
    color: #0c0c0e;
}



.nav_logo {
    /* margin-left: 15%; */
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700;
    width: 180px;
    text-align: center;
}

.nav_link {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1.5rem;
    transition: .3s;
    text-decoration: none;
}

.nav_link:hover {
    color: var(--white-color)
}

.nav_icon {
    font-size: 1.25rem
}



.modal-backdrop .show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
}

.nav-link .active {
    color: var(--white-color);
}

/* .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
} */

.height-100 {
    height: 100vh
}

@media screen and (min-width: 768px) {
    body {
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    /* .header_img {
        width: 100px;
        height: 100px
    } */

    /* .header_img img {
        width: 200px
    } */

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .modal-backdrop .show {
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px)
    }
}

/* cameraListCard & PlanogramCard */
.cameraListCard,
.planogramCard {
    background-color: #f4f7fc;
}

.PlanogramNotFound {
    position: relative;
    left: 40%;
    font-size: 25px;
    font-weight: 700;
}

.planogramMisplaced {
    font-size: 16px;
    font-weight: 600;
    margin-right: 21px;
    margin-top: 20px;
    width: auto;
    height: auto;
}

.cabinetMainContainer {
    background-color: #aaaaaa;
}

.selectCabinet {
    font-size: 18px;
    color: #525252;
    font-weight: 700;
}

.cameraListBody,
.planogramBody {
    background-color: #f5f5f5;
}

.SessionPagination {
    margin-left: 46%;
    margin-top: 50px;
    padding-bottom: 50px !important;
    font-size: 20px;
    font-weight: 800;
}

.sesiionNavigation {
    width: 200px;
    height: 200px;
}

.cameraListHeader,
.planogramHeader {
    color: #0c0c0e;
    font-weight: 600;
    font-size: 1.75rem;
    display: flex;
}

.FineContainer {
    height: auto;
    font-size: 18px;
    width: auto !important;
}

.refreshIcon {
    position: relative;
    left: 80%;
    width: fit-content;
    height: max-content;
    margin: 1% 2% 1% 0;
}

.addsubscription{
    float: right;
    width: fit-content;
    height: max-content;
}

.refreshIcon:hover {
    cursor: pointer;
}

.CameraList {
    margin-top: 3%;
}

.planogramCard {
    margin-top: 20px;
}

.planogramCard {
    height: 630px;
    width: 680px;
    position: relative;
    margin-left: -10px;
}

.PlanogramDetails {
    width: 550px;
    height: auto;
    margin-top: 20px;
}

.detailsData {
    flex: 0.5;
    width: 20px;
}

.detailsDataContianer {
    margin-left: 10%;
}

.detailsButtonRow {
    margin-top: 5px;
}

.detailsInputs {
    color: #5a5a5a;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 5px;
}

.cartRefreshIcons {
    margin-left: 10px;
}

.cartRefreshIcons:hover {
    cursor: pointer;
}

.planogramHistory {
    margin-top: 20px;
    width: 600px;
    height: auto;
}

.DetailsHeader {
    font-weight: 900;
    color: #0c0c0e;
    font-size: 18px;
}

.sub_Pagenation {
    position: absolute;
    bottom: 0px;
    display: flex;
}

.EventPagination {
    margin-top: 50px;
}

.pagination_Div,
.EventPagination {
    justify-content: center;
    display: flex;
    color: #0c0c0e;
    font-weight: bold;
}

.dashboard-table {
    width: 100%;
}

.accordion_content {
    width: 100% !important;
}

.accordion-Container {
    width: 100% !important;
    margin-top: 100px !important;
}

.dashboardCard {
    margin-top: 20px;
    width: 100% !important;
}

.dashboardCardHeader {
    color: #0c0c0e;
    font-weight: 600;
    font-size: 1.75rem;
}

.collapsing {
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
    transition: height 2s ease;
}

.accordion-container {
    margin-top: 100px;
}

@media (max-width: 1400px) {

    .planogramContent,
    .planogramContent {
        margin-left: -200px !important;
    }
}

.thumbnailImage {
    width: 150px;
    height: auto;
}

.thumbnailImage:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.PopUp_Image {
    height: 400px;
    width: 600px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.signOutTitle {
    font-size: 26px;
    font-weight: 800;
    color: #3e3e52;
}

.signoutSuccess,
.signoutFailure {
    width: 150px;
    height: 50px;
    font-size: 22px;
    margin-top: 50px;
}

.signoutFailure {
    margin-left: 20px;
}

.sessionCard {
    width: 100%;
    height: auto;
    box-shadow: 1px 2px 26px rgb(0 0 0 / 10%);
}

.sessionTable {
    margin-left: 10px;
}

.planogram_HistoryCard {
    margin-left: 200px;
}


/* Customer Journey */
.sessionAccordion {
    margin-top: 10px;
    border-radius: 7px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);
}

ul.pagination li a {
    text-decoration: none;
    color: black;
}

.page-item.active .page-link {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.statusRed {
    height: 30px;
    width: 30px;
    background-color: rgb(241, 31, 31);
    border-radius: 5px;
}

.videoClipIcon {
    width: 60px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);
    margin-left: 30%;
}

.videoClipIcon:hover {
    cursor: pointer;
}

.videoIcon {
    margin-top: 15px;
}

.sbwCard,
.cvCard {
    margin-top: 20px;
}

.modal-body {
    overflow: auto;
    max-height: 500px;
    margin-top: 20px;
}

.startingTimeTitle,
.endTimeTitle,
.dateTitle {
    font-weight: 600;
}

.ActionTable {
    width: 100%;
}

.cabinetContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100% !important;
    margin-right: 10px !important;
}

.dateInput {
    font-size: 16px;
    box-sizing: border-box;
    font-weight: 600;
    color: #5e5e5e;
    height: 44px;
}

.dateLabel,
.caninetInput,
.cabinetPlanogram {
    font-size: 16px;
    font-weight: 600;
}

.sessionRefreshButton {
    font-size: 16px;
    margin-top: 20px;
    margin-left: 50%;
}

.sessionRefresh {
    margin-left: 5px;
}

.mainaccordion {
    margin-top: 20px;
}

.cabinetMain {
    margin-top: 20px;
}

.cabinetAccordion {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);
}

.TopCard {
    margin-top: 50px;
}

.viewCabinet {
    width: 100px;
    height: 40px;
    font-size: 13px;
    margin-top: 20px;
    position: absolute;
    font-weight: 400;
    bottom: 0px;
    right: 10px;
}

.dashboardActionHeader {
    font-size: 20px;
    font-weight: 800;
    color: #3e3e52;
    background-color: #ffffff;
}

.accordion_Title,
.accordion_status {
    font-size: 20px;
    font-weight: 600;
    color: #3e3e52;
    display: flex;
    flex: 1;
}

.dashBoardIcon,
.planogramIcon {
    color: #0c0c0e;
    padding-bottom: 5px;
}

.planogramIcons:hover,
.signOut:hover {
    cursor: pointer;
}

.planogramIcons {
    width: 300px;
    margin: auto;
}

.spanStatus {
    height: 30px;
    width: 30px;
    background-color: rgb(240, 60, 60);
    border-radius: 5px;
}

.CabinetName,
.ProductTaken,
.SessionId {
    font-weight: 900;
    color: #3e3e52;
    line-height: 24px;
}

.cabinetSpan {
    font-size: 22px;
    font-weight: 600;
    color: #5e5e5e;
}

.SessionId {
    margin-top: 10px !important;
}

.ProductTaken {
    margin-top: 20px !important;
}

.productTakenspan {
    color: #525252;
    font-weight: 600;
    word-spacing: 1px;
    margin-left: 10px;
    margin-top: -10px;
}

.startingTime {
    color: rgb(26, 146, 26);
    /* font-size: 18px; */
    font-weight: 900;
}

.endTime {
    color: rgb(240, 60, 60);
    /* font-size: 18px; */
    font-weight: 900;
}

.date {
    color: #3e3e52;
    /* font-size: 16px; */
}

.Status {
    color: #3e3e52;
    font-size: 15px;
    font-weight: 600;
}

.statusRed {
    background-color: rgb(255, 65, 65);
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-top: 7px !important;
    margin-left: 10px;
}

.statusGreen {
    background-color: rgb(13, 173, 13);
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-top: 7px !important;
    margin-left: 10px;

}

.statusIndicator {
    margin-left: -100px;
}

.viewHistory {
    position: absolute;
    bottom: 10px;
    right: 10px;

}

.dashBoardHistory {
    width: 100% !important;
    height: auto;
    margin-top: 50px;
    box-shadow: 1px 2px 26px rgb(0 0 0 / 10%);
}

.accordion_container {
    margin-top: 100px;
    box-shadow: 1px 2px 26px rgb(0 0 0 / 20%);
}


/* Card */
.closeIcon:hover {
    cursor: pointer;
}

.imagePopup {
    margin-top: 20px;
}

.closeIcon {
    right: 1px;
    position: absolute;
    top: 10px;
    height: 30px;
    width: 30px;
}

.CloseIcon {
    right: 30px;
    position: absolute;
    top: 10px;
    height: 30px;
    width: 30px;
    color: white;
}

.CloseIcon:hover {
    cursor: pointer;
}

/* EventVideos */
.eventVideos {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background-color: rgb(0, 0.0, 0.95);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventVideos video {
    position: relative;
    max-width: 900px;
    outline: none;
}

@media (max-width:991px) {
    .eventVideos video {
        max-width: 90%;
    }
}

/* Testing Screen */
.card-MainContainer {
    margin-top: 50px;
}

.sbwData {
    margin-top: 6%;
    display: flex;
}

.sbwLabel {
    margin-right: 20px;
    padding-top: 10px !important;
}

.Card-Title {
    color: #525252;
    font-size: 18px;
    font-weight: 800;
    margin-top: 10px;
    margin-left: 10px;
}

.CabinetMain {
    margin-top: 20px;
}

.CabinetInput {
    margin-left: 16px !important;
}

.SessionInput {
    margin-left: 20px !important;
}

.SessionInput,
.CabinetInput {
    width: 250px !important;
    height: 40px;
}

.sessionStartBody {
    margin-left: 15px;
}

.startButton {
    width: 150px;

}

#sessionId,
#cabinetNo {
    color: #5a5a5a;

}

.timestamp,
.clearInput {
    margin-top: -7px;
    margin-left: 20px;
    width: 80px;
}

@media(max-width:991px) {

    .timestamp,
    .clearInput {
        margin-top: 10px;
    }
}

.dataNotFoundImage {
    width: 100px;
    height: 100px;
    margin-left: 43%;
}

.dataNotFound {
    width: 55%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 20%;
    height: auto;
    background-color: #f5f5f5;
}

/* videoClips */
.videoClips {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgb(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    background-color: #1482f7 !important;
    border-color: #1482f7 !important;
}

.rowTop {
    margin-right: 30px;
}

.videoClips video {
    position: relative;
    max-width: 900px;
    outline: none;
}

@media (max-width:991px) {
    .videoClips video {
        max-width: 90%;
    }
}

.form-label {
    text-align: right;
    clear: both;
    float: left;
    margin-right: 15px;
}

/* Product List */
.searchcolumn {
    margin-top: 2%;
    margin-bottom: 2%;
}

.searchcolumn2 {
    padding-top: 4%;
}

.searchAutoContainer {
    overflow: auto;
    max-height: 200px !important;
}

.searchAutoContainer:hover {
    cursor: pointer;
}

.searchAutoComplete {
    position: absolute;
    background-color: #ffffff !important;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 1px 2px 16px rgb(0 0 0 / 20%);
}

.productList {
    background-color: #fbfbfb;
    height: 100vh;
}

.Title {
    color: #525252;
    font-size: 25px;
    font-weight: 600;
    padding-top: 3%;
    padding-left: 3%;
}

.RegisterButton {
    position: absolute;
    right: 70px;
    margin-top: 28px;
}

.RegisterButton>button {
    height: 40px;
}

.listContainer {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 3%;
    background-color: #f0f0f0;
    height: auto;
    border-radius: 7px;
    box-shadow: 1px 2px 16px rgb(0 0 0 / 20%);
}

.GenerationCard {
    background-color: #f0f0f0;
    box-shadow: 1px 2px 16px rgb(0 0 0 / 16%);
    width: 100%;
}

.custom-file {
    margin-top: 10px;
}

.GenerationId {
    padding-top: 1%;
    margin-left: 5%;
    margin-right: 3%;
}

.inputTag {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-left: 10px;
    width: 300px;
}

.unRegisterImage {
    width: 100px;
    height: 100px;
    margin-top: -40px !important;
    margin-left: 50px;
}

.unRegisterImage:hover {
    cursor: pointer;
}

.uploadButton {
    margin-left: 10px;
    width: 120px !important;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 500 !important;

}

.uploadButton:hover {
    cursor: pointer;
}

input[type="submit"] {
    text-transform: capitalize;
}

.GenerateForm {
    padding-top: 5px;
}

.Generate_Title {
    color: #525252;
    font-size: 18px;
    font-weight: 600;
}

.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);

}

.search input {

    height: 50px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
}


.search input:focus {

    box-shadow: none;
    border: 2px solid blue;
}

.search .fa-search {
    position: absolute;
    top: 18px;
    left: 16px;
}

.search button {

    position: absolute;
    top: 5px;
    right: 5px;
    height: 40px;
    width: 70px;
    background: blue;
}

.searchBar {
    padding-top: 20px;
    padding-left: 20px;
}

.listTable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    padding-bottom: 2%;
    padding-top: 3%;
    overflow: auto;
    max-height: 350px;

}

.unregisterScrollbar {
    overflow: auto;
    max-height: 350px;
}

.unregisterTable {
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 3%;
}

.productlistTable {
    margin-top: -3%;
}

/* Register_Screen */
.RegisterContainer,
.inventoryUpdateContainer {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 3%;
    background-color: #f0f0f0;
    height: auto;
    border-radius: 7px;
    box-shadow: 1px 2px 26px rgb(0 0 0 / 15%);
}

.RegisterImage {
    box-shadow: 1px 2px 16px rgb(0 0 0 / 15%);
    width: 300px;
}

.RegisterImage:hover {
    cursor: pointer;
}

.TrainingLabel {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 600;

}

.RegisterInput {
    width: 300px !important;
    height: 50px;
    margin-left: 20px;
}

.registerImageColumn {
    padding-top: 5%;
}

.registerColumn {
    margin-top: 3%;
    margin-bottom: 4%;
    margin-left: 3%;
}

.regsiterRow {
    margin-top: 25px;
}

.registerLabel {
    font-size: 16px;
    font-weight: 600;
    color: #5a5a5a;
}

.Register_Button_div {
    justify-content: center;
    margin-left: 45%;
    margin-bottom: 5%;

}

.registerButton {
    width: 100px !important;
    height: 40px;
    font-size: 16px !important;
    margin-bottom: 5%;
    font-weight: 500 !important;
}

/* Alarm Screen */
.alarmCard {
    margin: 10% auto;
    margin-bottom: 10px;
    float: none;
    width: 450px;
    height: 280px;
    text-align: center;
    box-shadow: 1px 2px 26px rgb(0 0 0 / 30%);
}

.AlarmDeactivate {
    font-size: 28px;
    font-weight: 600;
    margin-top: 20px !important;
}

.alarmButton {
    margin-top: 20px;
}

.alarmImage {
    height: 50px;
    width: 50px;
}

.statusContainer {
    display: flex;
    margin-left: 5%;
}

.AlarmStatusLabel,
.DeactivateLabel {
    font-weight: 600;
    color: #525252;
    font-size: 24px;
}

.AlarmStatus {
    margin-top: 10px;
    font-size: 18px;
    display: flex;
    width: 180px;
    border-radius: 10px;
}

.AlarmLabel {
    margin-left: 20px;
}

.DeactivateLabel {
    margin-left: 65%;
}

.deactivateButton {
    margin-left: 70%;
    font-size: 18px;
    width: auto;
    height: auto;
}

.bell {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 3s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

input[type="number"] {
    width: 100%;
    padding: 0 0 0 10px;
    margin: 0;
    color: #8a8b8e;
    border: 1px solid #c2c0ca;
    font-style: normal;
    font-size: 16px;
    appearance: none;
    position: relative;
    display: inline-block;
    background: none;
}

input[type="number"]:focus {
    border-color: #3ca9e2;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }

    1% {
        -webkit-transform: rotateZ(30deg);
    }

    3% {
        -webkit-transform: rotateZ(-28deg);
    }

    5% {
        -webkit-transform: rotateZ(34deg);
    }

    7% {
        -webkit-transform: rotateZ(-32deg);
    }

    9% {
        -webkit-transform: rotateZ(30deg);
    }

    11% {
        -webkit-transform: rotateZ(-28deg);
    }

    13% {
        -webkit-transform: rotateZ(26deg);
    }

    15% {
        -webkit-transform: rotateZ(-24deg);
    }

    17% {
        -webkit-transform: rotateZ(22deg);
    }

    19% {
        -webkit-transform: rotateZ(-20deg);
    }

    21% {
        -webkit-transform: rotateZ(18deg);
    }

    23% {
        -webkit-transform: rotateZ(-16deg);
    }

    25% {
        -webkit-transform: rotateZ(14deg);
    }

    27% {
        -webkit-transform: rotateZ(-12deg);
    }

    29% {
        -webkit-transform: rotateZ(10deg);
    }

    31% {
        -webkit-transform: rotateZ(-8deg);
    }

    33% {
        -webkit-transform: rotateZ(6deg);
    }

    35% {
        -webkit-transform: rotateZ(-4deg);
    }

    37% {
        -webkit-transform: rotateZ(2deg);
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
    }

    41% {
        -webkit-transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
    }

    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }

    1% {
        -moz-transform: rotate(30deg);
    }

    3% {
        -moz-transform: rotate(-28deg);
    }

    5% {
        -moz-transform: rotate(34deg);
    }

    7% {
        -moz-transform: rotate(-32deg);
    }

    9% {
        -moz-transform: rotate(30deg);
    }

    11% {
        -moz-transform: rotate(-28deg);
    }

    13% {
        -moz-transform: rotate(26deg);
    }

    15% {
        -moz-transform: rotate(-24deg);
    }

    17% {
        -moz-transform: rotate(22deg);
    }

    19% {
        -moz-transform: rotate(-20deg);
    }

    21% {
        -moz-transform: rotate(18deg);
    }

    23% {
        -moz-transform: rotate(-16deg);
    }

    25% {
        -moz-transform: rotate(14deg);
    }

    27% {
        -moz-transform: rotate(-12deg);
    }

    29% {
        -moz-transform: rotate(10deg);
    }

    31% {
        -moz-transform: rotate(-8deg);
    }

    33% {
        -moz-transform: rotate(6deg);
    }

    35% {
        -moz-transform: rotate(-4deg);
    }

    37% {
        -moz-transform: rotate(2deg);
    }

    39% {
        -moz-transform: rotate(-1deg);
    }

    41% {
        -moz-transform: rotate(1deg);
    }

    43% {
        -moz-transform: rotate(0);
    }

    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

/* InventoryFilter */
.inventorydropdown {
    display: flex;
    flex: 1;
}

.InventoryFilter {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 3%;
}

.input-group {
    width: 350px !important;
}

.custom-select {
    box-shadow: 1px 2px 26px rgb(0 0 0 / 10%);
    height: 50px;
    font-size: 18px;
    font-weight: 600;
}

.cabinet_value {
    padding-left: 10%;
}

.editImage {
    margin-left: 30%;
    margin-top: 5%;
}

.editImage:hover,
.planogramToggle:hover {
    cursor: pointer;
}

/* Update Inventory */
.UpdateButton {
    text-align: center;
}

.updateContainer {
    padding-top: 50px;
    padding-bottom: 50px;
}

/* .rp-stage .rp-image {
    width: 100% !important;
    height: auto !important;
}

.rp-stage .rp-shapes {
    width: 100% !important;
    height: auto !important;
} */

.cabinetTitle,
.cameraTitle {
    font-size: 16px;
    font-weight: 900;
    color: #3e3e52;
    line-height: 24px;
}

.cabinetValues,
.cameraValues {
    margin-top: 7px !important;
}

.planogramToggle {
    margin-top: 8%;
}

.cabinetListCard,
.cameraListCard,
.cabinetViewDetails,
.cameraViewDetails {
    margin-top: 1%;
}

.cabinetSearchBar {
    width: 300px;
    display: flex;
}

.dashboard_main {
    overflow: hidden;
}

.cabinetListMain {
    max-height: 400px;
    overflow-y: auto;
}

.mainCard {
    margin-top: 2%;
    max-height: 500px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.pageHeader {
    margin-top: 5%;
}

.CameraListHeader {
    color: #3e3e52;
    font-size: 20px;
    font-weight: 600;
}

.cabinetListCard {
    background-color: #f4f7fc;
}

.planogramStatus {
    color: #3e3e52;
    font-size: 30px;
    margin-left: 6%;
}

.PopupContainer {
    width: 1000px;
}

.updatePlanogram,
.autofillPlanogram {
    font-size: 16px;
    width: 100px;
    height: auto;
}

.updatePlanogram {
    margin-right: 12%;
}

.autofillPlanogram {
    margin-left: 30%;
}

.text-centers {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.cabinetToggle {
    margin-left: 10%;
    margin-top: 12%;
}

.cabinetListHeader {
    color: #525252;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2% !important;
}

.nav_link:hover {
    background-color: var(--secondary-color);
}

.nav_link:hover .planogramIcon,
.nav_link:hover .nav_name {
    color: white !important;
}

.onboardingCard {
    margin-top: 5%;
}

.onboardingHeader {
    color: #5e5e5e;
    font-size: 20px;
    font-weight: 600;
}

.onboardingInputs {
    margin-top: 3%;
    margin-left: 2%;
}

.cameraOnboarding {
    width: 100%;
}

.cameraInput {
    width: 200px;
}

.addButton {
    margin-bottom: 3%;
}
.deleteCamera {
    margin-top: 7%;
}

/* to remove the increment and decrement in input type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.editCabinet,
.deleteCabinet {
    margin-right: 5%;
}

.nav_active .planogramIcon,
.nav_active .nav_name,
.nav_active {
    color: white !important;
    background-color: var(--secondary-color);
}

/* .active::before {
    content: '' !important;
    position: absolute !important;
    left: 0 !important;
    width: 2px !important;
    height: 15px !important;
    background-color: # !important;
} */

.toggle:hover {
    cursor: pointer;
}

.vendorToggle {
    font-size: 25px !important;
    margin-right: 2% !important;
}

.editBack {
    position: relative;
    top: 40px;
}

.searchButton {
    width: 50px;
    z-index: inherit;
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    font-weight: 1000;
    background-color: var(--secondary-color);
    color: white;
}

.font-bold {
    font-weight: 1000;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.big-btn {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    color: white;
}

.cursor-pointer {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.sidebar-overflow {
    max-height: 80%;
    overflow-y: auto;
}

.addProductPopup {
    max-width: 900px !important;
    margin-left: 25%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--secondary-color);
    color: white;
}

.inventory-nav,.subscriptionNav{
    background-color: #eeeeee;
    padding: 10px;
}
.subscriptionFilter{
    height: 44px;
    font-size: 14px !important;
}
.subscriptionFilter:focus{
    border-color:#1482f7 !important;
}
.Subscribed{
    color: rgb(0, 172, 0);
}
.renewed{
    color: rgba(254, 254, 0, 0.89);
}
.revoked{
    color: red;
}
.Subscribed,.renewed,.revoked{
    font-weight: 600;
}

.subscriptionName,.subscriptionPrice{
    height: 40px;
}
.subscriptionPrice,.subscriptionType{
    width: 200px !important;
    height: 50px !important;
    font-size: 14px !important;
}
.firstPrice{
    margin-left: -6% !important;
}
.subscriptionCheckbox{
    width: 20px;
    height: 20px;
}
.cabinetListCard {
    background-color: white;
}

.mainContent {
    margin-top: 5%;
}

.cabinetContainer {
    display: flex;
}

.flexChild {
    flex: 1;
}

.errorMessage {
    color: red;
}

.select-box-height {
    height: calc(3.5rem + 2px) !important;
}

.appLoader {
    z-index: 1200 !important;
}
.searchControlTower{
    width:55% !important;
    align-items: end;
    height: 35px !important;
}
.popUp {
    z-index: 11;
}

.apploaderBackground {
    z-index: 1199;
}

p {
    font-size: 14px;
}

tbody {
    font-size: 14px;
}

thead {
    font-size: 14px;
}

.green-color {
    color: green;
    font-weight: 500;
}

.yellow-color {
    color: rgb(250, 204, 0);
    font-weight: 700;
}

.red-color {
    color: red;
    font-weight: 500;
}

.center-align {
    margin: auto;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}

.planogramCheck {
    width: 100% !important;
}

.planogramPopCard {
    /* overflow-y: auto; */
    /* max-height: 350px; */
    height: calc(100vh - 80px);
    /* margin: 20px; */
    /* overflow-x: hidden; */
}

.planogram_container {
    display: flex;
    flex-direction: row;
}

.planogram_width {
    width: 65%;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
}

.rack-canvas {
    height: 80vh;
}

.planogram_3d_container {
    margin-top: 10px;
    margin-right: 10px;
    width: 35%
}

.planogramCardBody {
    margin: 5px !important;
}

.planogramNextPrevious {
    position: relative;
}

.planogramNext {
    margin-left: 45% !important;
}

.planogramComplete {
    width: 100px;
    height: 40px;
    font-size: 13px;
    margin-top: 30px !important;
    position: absolute;
    font-weight: 400;
    bottom: 0px;
    right: 0px;
    color: green;
}

.planogramComplete:hover {
    cursor: pointer;
}

.fieldContainer {
    display: flex;
}

.css-qbdosj-Input input {
    height: 30px;
}

.css-166bipr-Input {
    height: 30px;

}

.css-1h01tm3-Input {
    height: 30px;
    z-index: 1000 !important;
}

.pagination {
    margin-bottom: 10px !important;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.rp-stage .rp-image {
    position: relative;
}

/* .planogramPopCard {
    max-height: auto !important;
    min-height: 430px !important;
} */

.mainaccordion {
    margin-top: 0px !important;
}

.inputCabinet,
.inputPlanogram {
    height: 30px;
    width: fit-content !important;
}

.planogramToggle {
    margin-top: 10px;
}

.publishButton {
    display: flex;
    justify-content: flex-end;
}

.fetchNewFrame {
    display: flex !important;
    justify-content: flex-end;
}

.fetchFrame {
    width: fit-content;
    height: max-content;
    justify-content: center;
}

.pictureContainer {
    width: 480px !important;
}

.healthCheckContainer {
    min-height: 300px;
    position: relative;
}

.serverSelect,
.subCategorySelect {
    height: 43px !important;
}

.alert-danger {
    background-color: feded;
    color: #231f20;
    color: black;
    border: 0;
}

.current_product {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 32vw;
    overflow: auto;
    padding: 10px 5px;
}

.current_product .card {
    position: relative;
    border: none;
}

.current_product_img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    display: inline-block;
    background-color: #d2d2d2;
    padding: 0px;
}

.current_product_circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: white;
    position: absolute;
    top: -5px;
    left: -5px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(1vh + 3px);
    font-weight: 600;
}

.imageSlideShow {
    height: auto !important;
    padding: 10px !important;
    min-height: 550px;
    overflow: hidden !important;
}

.sliderDetails {
    margin-bottom: 20px;
}

.imageLabels {
    font-size: 18px;
    font-weight: 600;
}

.imageValue {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 500;
}

.showImageRow {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 10px;
}

.vendorPassword {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}